import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";
import { Button, Carousel, Image, Modal } from "react-bootstrap-v5";
import {
  BASE_URL,
  GET_SITESETTINGS,
  GET_SLIDERSETTINGS,
} from "../../constant/constants";
import { Link } from "react-router-dom";

const Hero = () => {
  const [contents, setContents] = useState([]);
  const [show, setShow] = useState(false);
  const [modalImage, setModalImage] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // //console.log("sliderssss", contents);

  //for content
  useEffect(() => {
    fetch(`${GET_SLIDERSETTINGS}`)
      .then((response) => response.json())
      .then((data) => {
        // setContents(data.homepage_sliders);
        // //console.log("sliderImages", data.homepage_sliders);
        setContents(data.homepage_sliders);
        // data.homepage_sliders.map((n) => setContents(n.image));
      });

    // First visit to the page, show the modal
    handleShow();

    fetch(GET_SITESETTINGS)
      .then((response) => response.json())
      .then((data) => {
        //console.log("GET_SITESETTINGS", data);
        setModalImage(data.general_settings[0]);
      })
      .catch(() => {});
  }, []);
  return (
    <>
      {modalImage?.slider && (
        <Modal size="lg" show={show} centered onHide={handleClose}>
          <Image
            fluid={true}
            thumbnail={true}
            rounded={true}
            style={{
              filter: "brightness(80%)",
            }}
            src={`${BASE_URL}${modalImage?.slider}`}
            alt="First slide"
          />
        </Modal>
      )}
      <Carousel fade>
        {contents.map((n) => (
          <Carousel.Item interval={5000}>
            <img
              className="d-block w-100"
              // style={{ objectFit: "cover", opacity: "0.7" }}
              style={{ filter: "brightness(40%)" }}
              height={700}
              src={`${BASE_URL}${n.image}`}
              alt="First slide"
            />

            <Carousel.Caption style={{ bottom: "15.25rem" }}>
              {n.title && (
                <Interweave
                  allowAttributes
                  allowElements
                  disableLineBreaks={false}
                  content={n.title}
                />
              )}
              {n.details && (
                <Link to={n?.link}>
                  <Button
                    style={{
                      fontWeight: "700",
                      color: "white",
                      fontSize: "large",
                      marginTop: "40px",
                      padding: "15px",
                      width: "200px",
                      border: "none",
                      backgroundColor: "rgb(255, 188, 19)",
                    }}
                  >
                    {n.details || "Our Portfolio"}
                  </Button>
                </Link>
              )}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};

export default Hero;
