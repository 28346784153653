import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  BASE_URL,
  GET_EMPLOYEES_WITHOUT_PAGINATION,
} from "../../constant/constants";

const OurEmployees = () => {
  const [employees, setEmpoyees] = useState([]);

  //for content
  useEffect(() => {
    fetch(`${GET_EMPLOYEES_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setEmpoyees(data.employees);
      })
      .catch(() => {});
  }, []);
  //console.log("employees", employees);

  return (
    <section className="wpo-features-section-s6 section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            {/* <div className="wpo-section-title">
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === "Companies" ? (
                    <div className="" key={index}>
                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={value}
                      />
                    </div>
                  ) : (
                    " "
                  )
                )
              )}
            </div> */}
          </div>
        </div>
        <div className="row">
          {employees.map((employee) => (
            <div
              key={employee.id}
              className="col col-xl-4 col-lg-4 col-sm-6 col-12"
            >
              <div
                className="wpo-features-item"
                style={{
                  margin: "10px",
                  padding: "50",
                  borderRadius: "20px",
                }}
              >
                <div>
                  <img
                    style={{
                      borderRadius: "50%",
                      display: "block",
                      width: "130px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      height: "130px",
                    }}
                    src={`${BASE_URL}${employee.image}`}
                    alt=""
                  />
                </div>
                <div>
                  <h4 style={{ textAlign: "center", marginTop: "20px" }}>
                    {employee.first_name} {employee.last_name}
                  </h4>
                  <p style={{ textAlign: "center" }}>Email: {employee.email}</p>
                  <p style={{ textAlign: "center" }}>
                    Phone: {employee.primary_phone}
                  </p>
                  <p style={{ textAlign: "center" }}>
                    Address: {employee.street_address_one}{" "}
                    {employee?.street_address_two}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurEmployees;
